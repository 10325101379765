<template>
  <v-row v-bind="fieldAttrRow" no-gutters>
    <input type="hidden" name="c_obj" :value="JSON.stringify(localValue)" />
    <v-col cols="12">
      <!-- <v-divider></v-divider> -->

      <div></div>
      <!--
      <v-alert type="info">At least one Step should be kept!</v-alert>
      -->
      <!-- <div style="height: 25px; display: inline-block"></div> -->

      <div class="testGroupsContainer">
        <v-card>
          <v-tabs v-model="tab" background-color="primary" dark>
            <v-tab
              v-for="(groupValue, groupKey) in stepsGroups"
              :key="groupValue"
              :id="'testCaseGroupTabHeader_' + groupValue"
            >
              <draggable
                v-model="droped[groupValue]"
                @change="updateGroup(groupValue, ...arguments)"
                :group="{
                  name: groupValue,
                  put: true,
                  pull: false,
                }"
                ghostClass="d-none"
                draggable=".draggable"
                class="my-8 mx-12"
                >{{ groupKey }}({{ testsCount(groupValue) }})
              </draggable></v-tab
            >
          </v-tabs>
          <v-tabs-items v-model="currentTab">
            <v-tab-item
              v-for="(groupValue, i, index) in stepsGroups"
              :key="groupValue"
            >
              <v-card flat :id="'testCaseGroupTabBody_' + groupValue">
                <v-card-text>
                  <v-form
                    :ref="'tabFormValidation_' + index"
                    v-model="tabFormValidation"
                  >
                    <testCasesGroup
                      :isValid="isValid"
                      :stepsGroups="stepsGroups"
                      :groupValue="groupValue"
                      :templateValue="templateValuesArray[groupValue]"
                      :groupedStepDependentInfo="
                        groupedStepDependentInfo(groupValue)
                      "
                      :result="result"
                      @group-value-changed="
                        updateTestValue(groupValue, ...arguments)
                      "
                      v-on="$listeners"
                    ></testCasesGroup>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
    </v-col>
  </v-row>
  <!-- <script src='{getFileVersionPath("cfg/webDriverTemplates.js")}'></script> -->
</template>

<!--<script type='text/javascript' src='{getFileVersionPath("cfg/testCases.js")}'></script>-->



<!--script  type="module" src='{getFileVersionPath("js/multiselect.js")}'></script-->

<script>
import testCasesGroup from "@/components/legacy/testCasesGroup.vue";
import { getfieldAttrRow, getFieldsAttributes } from "@/js/helper.js";
//import { cleanUpGroupedAttrs } from "@/js/diameter.js";
import draggable from "vuedraggable";
//import testCases from "@/cfg/testCases.json";
export default {
  components: { testCasesGroup, draggable },
  watch: {
    tabFormValidation(isValid) {
      this.$emit("changeIsValid", isValid);
    },

    isValid(isValid) {
      if (isValid) {
        let oldVal = this.currentTab;

        if (
          typeof this.$refs["tabFormValidation_" + oldVal] != "undefined" &&
          this.$refs["tabFormValidation_" + oldVal][0] != "undefined"
        ) {
          let form = this.$refs["tabFormValidation_" + oldVal][0];
          let valid = form.validate();
          if (!valid) {
            this.$emit("changeIsValid", valid);
          }
          return !valid;
        }
      }
    },

    tab(newVal, oldVal) {
      if (typeof this.$refs["tabFormValidation_" + oldVal] != "undefined") {
        let form = this.$refs["tabFormValidation_" + oldVal][0];
        if (!form.validate()) {
          this.$nextTick(function () {
            this.tab = oldVal;
          });
        } else {
          this.currentTab = newVal;
        }
      }
    },
  },
  computed: {
    fieldAttrRow: function () {
      return getfieldAttrRow(this.fieldAttributes, this.field);
    },
    fieldAttributes: function () {
      let fieldAttributes = getFieldsAttributes(this.result, this.field);
      //console.log(fieldAttributes, this.field);
      return fieldAttributes;
    },

    templateValuesArray() {
      let templateValuesArray = { pre: {}, post: {}, test: {} };

      this.$set(templateValuesArray, "pre", this.localValue["pre"]);
      this.$set(templateValuesArray, "post", this.localValue["post"]);
      for (let [index, testValue] of Object.entries(this.localValue)) {
        if (index != "pre" && index != "post") {
          this.$set(templateValuesArray["test"], index, testValue);
        }
      }

      //  }

      return templateValuesArray;
    },
    localValue: {
      get() {
        let value = {};
        if (this.result.valueLine.c_obj != this.stringfiedValue) {
          try {
            console.log("parsed again");
            value = JSON.parse(this.result.valueLine.c_obj);
          } catch (error) {
            value = {};
          }
        } else {
          value = this.parsedValue;
        }
        return value;
      },
      set(newVal) {
        this.updateLocalValue(newVal);
      },
    },
  },
  data: function () {
    return {
      tabFormValidation: false,
      // parsedValue: JSON.parse(this.result.valueLine.c_obj),
      droped: { pre: [], test: [], post: [] },
      parsedValue: {},

      stringfiedValue: this.result.valueLine.c_obj,
      // localValue: {},
      //templateValuesArray: {},
      tab: 1,
      currentTab: 1,
      stepsGroups: { Setup: "pre", Test: "test", Clearing: "post" },

      //  attributeIncrement: 0,
      //objectsArray:{},
      //templates:{*json_encode(result.additional.templates)*},
      //  httpOperators: this.result.additional.httpOperators,
      //  diameterEnumValues: this.result.additional.diameterEnumValues,
      // protocolsMapping: this.result.additional.protocolsMapping,

      //  readyObjects: {},

      // responseattr:dictionarisArray.RADIUS_ACCESS,
      // subscribers: this.result.additional.subscribers,

      //enableSuppress:true,
      //operators:result.additional.operators,
      //  attrDic: this.result.additional.attrDic,
      //  testResult: ["error", "warning"],
      // endOptions: ["abort", "ignore"],
      //endOptions:["abort","ignore","jump"],
      // retryOptions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      //  selectedArray: {},
      //imsiValues:[],
      // androidTitles: this.result.additional.androidTitles,
      // androidAttributes: this.result.additional.androidAttributes,

      //  testObjectHelp: this.result.additional.testObjectHelp,
      // httpHelp: this.result.additional.httpHelp,
      //devices: this.result.additional.devices,
      // rattuxCountries: this.result.additional.rattuxCountries,

      // seleniumBox: this.result.additional.seleniumBox,
      //pingEvalType: this.result.additional.pingEvalType,
      // pingEvalOperator: this.result.additional.pingEvalOperator,

      // httpAttrIncrement: 0,

      //evalAction:{*json_encode(result.additional.evalAction)*},

      // stepHelp: this.result.additional.stepHelp,

      //attrSource: this.result.additional.source,
      attributes: {},
      // timeOut: {},
      //enumerated: {},
      // testCasesOriginal: this.result.additional.testCasesOriginal,

      // var inputArray=Object.keys(fieldsObject.data( "testCases"));
      //inputArray: this.result.additional.testCases,

      // domainApns: this.result.additional.apns,
      // wdaCommands: this.result.additional.wdaCommands,
      //wdaTemplatesNames: this.result.additional.wdaTemplatesNames,
      // wdaTemplatesObjects: this.result.additional.wdaTemplatesObjects,

      //fieldsObject.data( "imsiValues", [] );
      // imsiValues: this.result.additional.imsiJsonString,
      // testEnviromentObject: this.result.additional.testEnviromentObject,

      //fieldsObject.data( "stepsIdentifiers", [] );

      //enableSuppress: true,
      // disableFixed: true,
      // operators: this.result.additional.operators,

      // ldapOperations: this.result.additional.ldapOperations,

      // connectionsIds: this.result.additional.additionalStepsInfo[
      //   "connectionsIds"
      // ],

      //deprecatedMessage: this.language.configureAndRunTests.deprecatedMessage
    };
  },
  created() {
    let parsedValue;
    try {
      parsedValue = JSON.parse(this.result.valueLine.c_obj);
    } catch (error) {
      parsedValue = { 1: {} };
    }
    this.parsedValue = parsedValue;
  },
  methods: {
    testsCount(groupValue) {
      let testsCount = 0;
      if (typeof this.templateValuesArray[groupValue] != "undefined") {
        testsCount = Object.keys(this.templateValuesArray[groupValue]).length;
      }
      return testsCount;
    },
    convertToStepObject(local) {
      let stepObject = {};
      let caller = this;
      local.forEach((element, index) => {
        caller.$set(stepObject, index + 1, element);
      });
      return stepObject;
      // return local;
    },
    updateGroup(groupValue, value) {
      // console.log(groupValue, value);
      let templateValue = this.templateValuesArray[groupValue];
      if (typeof templateValue == "undefined") {
        templateValue = {};
      }

      let local = Object.values(templateValue);
      local.unshift(value.added.element);

      // let length = Object.keys(templateValue).length;
      // this.$set(templateValue, length + 1, value.added.element);
      this.updateTestValue(groupValue, this.convertToStepObject(local));
    },
    updateLocalValue(newVal) {
      this.stringfiedValue = JSON.stringify(newVal);
      this.templateStoreValue = newVal;
      this.parsedValue = newVal;
      this.$emit("input", this.stringfiedValue);
      //  this.$emit("input", JSON.stringify(newValue));
    },
    groupedStepDependentInfo(groupValue) {
      let groupedStepDependentInfo = {};
      if (
        typeof this.result.additional.stepDependentInfo != "undefined" &&
        typeof this.result.additional.stepDependentInfo[groupValue] !=
          "undefined"
      ) {
        groupedStepDependentInfo = this.result.additional.stepDependentInfo[
          groupValue
        ];
      }
      // //  console.log(this.result.additional.stepDependentInfo,groupValue,groupedStepDependentInfo);
      return groupedStepDependentInfo;
    },
    updateTestValue(group, value) {
      if (group == "test") {
        // delete none exsisting steps
        for (let index of Object.keys(this.localValue)) {
          if (index != "pre" && index != "post") {
            if (typeof value[index] == "undefined") {
              this.$delete(this.localValue, index);
            }
          }
        }
        for (let [index, testValue] of Object.entries(value)) {
          this.$set(this.localValue, index, testValue);
        }
      } else {
        this.$set(this.localValue, group, value);
      }
      this.updateLocalValue(this.localValue);

      // console.log(group, value, Object.keys(value));
      // let overallValue = {};
      // this.$set(this.templateValuesArray, group, value);
      // this.$set(overallValue, "pre", this.templateValuesArray.pre);
      // this.$set(overallValue, "post", this.templateValuesArray.post);
      // // this.localValue.pre = this.templateValuesArray.pre;
      // //this.localValue.post = this.templateValuesArray.pre;
      // this.localValue = {
      //   ...overallValue,
      //   ...this.templateValuesArray.test,
      // };
    },
    // getDictionarisArray: function () {
    //   let dictionarisArray = {};
    //   dictionarisArray.RADIUS_ACCESS = this.result.additional.objectAttr;
    //   dictionarisArray.RADIUS_ACCOUNTING = dictionarisArray.RADIUS_ACCESS;
    //   dictionarisArray["3GPP_GX"] = this.result.additional.diameterAttrs;
    //   dictionarisArray.DCCA = this.result.additional.dccaAttrs;
    //   dictionarisArray.diameterAttrs = dictionarisArray.DCCA;
    // },
    // getResponseDictionarisArray: function () {
    //   let responseDictionarisArray = {};
    //   responseDictionarisArray.RADIUS_ACCESS = this.dictionarisArray.RADIUS_ACCESS;
    //   responseDictionarisArray.RADIUS_ACCOUNTING = this.dictionarisArray.RADIUS_ACCOUNTING;
    //   responseDictionarisArray["3GPP_GX"] = cleanUpGroupedAttrs(
    //     this.dictionarisArray["3GPP_GX"]
    //   );
    //   responseDictionarisArray.DCCA = cleanUpGroupedAttrs(
    //     this.dictionarisArray.DCCA
    //   );
    //   responseDictionarisArray.diameterAttrs = responseDictionarisArray.DCCA;
    // },
  },
  props: ["result", "field", "isValid"],
};
</script>